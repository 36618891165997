import { Button, Input, Select, Radio, Form, notification, Drawer } from "antd";
import { useState } from "react";
import axios from "axios";

import endpoints from "../../utils/endpoints.js";

function Create({ open, close, optionsType, commissionType, commissionPosition }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  function handleSubmit(values) {
    setIsButtonLoading(true);

    axios
      .post(endpoints.commission.create, {
        data: values,
      })
      .then((res) => {
        close();
        setIsButtonLoading(false);
        form.resetFields();
        notification.success({
          message: "Sucesso!",
          description: "Membro da comissão foi adicionado com sucesso!",
        });
      })
      .catch((error) => {
        console.log(error);
        setIsButtonLoading(false);
        notification.error({
          message: "Oops!",
          description: "Algo de errado aconteceu! Tente novamente mais tarde.",
        });
      });
  }

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: "Faltam alguns campos para preencher!",
    });
  }

  function handleCloseDrawer() {
    close();
    form.resetFields();
  }

  return (
    <Drawer
      id="create-commission"
      width={800}
      title={`Adicionar membro`}
      open={open}
      onClose={handleCloseDrawer}
      maskClosable={false}
      extra={[
        <Button className="mr-10" size="large" type="primary" onClick={form.submit} loading={isButtonLoading} disabled={isButtonLoading}>
          Adicionar
        </Button>,
        <Button size="large" onClick={close}>
          Cancelar
        </Button>,
      ]}
    >
      <Form form={form} id="add-moderator-form" layout="vertical" onFinish={handleSubmit} onFinishFailed={handleWarningMissingFields}>
        <Form.Item name="type" rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
          <Radio.Group options={optionsType} optionType="button" buttonStyle="solid" className="radio-type" />
        </Form.Item>
        <Form.Item name="name" label="Nome" rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
          <Input size="large" placeholder="Nome..." />
        </Form.Item>
        <Form.Item name="commission_type_id" label="Comissão" rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
          <Select
            showSearch
            allowClear
            size="large"
            placeholder="Tipo de comissão..."
            filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
            options={commissionType.map((value, index) => ({ value: value.id, label: value.name }))}
          />
        </Form.Item>
        <Form.Item name="commission_position_id" label="Posição" rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
          <Select
            showSearch
            allowClear
            size="large"
            placeholder="Posição..."
            filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
            options={commissionPosition.map((value, index) => ({ value: value.id, label: value.name }))}
          />
        </Form.Item>
        <Form.Item name="work" label="Local de trabalho">
          <Input size="large" placeholder="Local de trabalho..." />
        </Form.Item>
      </Form>
    </Drawer>
  );
}

export default Create;
