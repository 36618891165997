import { Modal, Button, Input, Form, notification } from "antd";
import { useState } from "react";
import axios from "axios";

import endpoints from "../../utils/endpoints.js";

function CreateType({ open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  function handleSubmit(values) {
    setIsButtonLoading(true);

    axios
      .post(endpoints.commission.createType, {
        data: values,
      })
      .then((res) => {
        close();
        setIsButtonLoading(false);
        form.resetFields();
        notification.success({
          message: "Sucesso!",
          description: "Tipo de comissão foi adicionado com sucesso!",
        });
      })
      .catch((error) => {
        console.log(error);
        setIsButtonLoading(false);
        notification.error({
          message: "Oops!",
          description: "Algo de errado aconteceu! Tente novamente mais tarde.",
        });
      });
  }

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: "Faltam alguns campos para preencher!",
    });
  }

  function handleCloseModal() {
    close();
    form.resetFields();
  }

  return (
    <Modal
      id="create-commission-type"
      title={`Adicionar tipo de comissão`}
      open={open}
      onCancel={handleCloseModal}
      footer={[
        <Button size="large" type="primary" onClick={form.submit} loading={isButtonLoading} disabled={isButtonLoading}>
          Adicionar
        </Button>,
        <Button size="large" onClick={handleCloseModal}>
          Cancelar
        </Button>,
      ]}
    >
      <Form form={form} id="add-commission_type-form" layout="vertical" onFinish={handleSubmit} onFinishFailed={handleWarningMissingFields}>
        <Form.Item name="name" label="Nome" rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
          <Input size="large" placeholder="Nome..." />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CreateType;
