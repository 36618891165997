import { Row, Col, Button, Input, Form, notification } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../utils/config.js";

import "./Settings.css";
import "react-quill/dist/quill.snow.css";
import endpoints from "../../utils/endpoints.js";
import Dragger from "antd/es/upload/Dragger.js";
import { FileImageOutlined } from "@ant-design/icons";
import upload from "../../utils/upload.js";

function SettingsSponsor({ data }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [filePreview, setFilePreview] = useState("");
  const [form] = Form.useForm();

  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const uploadProps = {
    name: "file",
    maxCount: 1,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFilePreview("");
    },
    beforeUpload: (file) => {
      handlePreview(file, "image");
      setFileList([file]);
      return false;
    },
    fileList: fileList,
    defaultFileList: [],
  };

  useEffect(() => {
    if (data.length > 0) {
      let auxData = data.filter((item) => item.name === "sponsor")[0];
      let auxForm = JSON.parse(auxData.json);
      if (auxForm.img) {
        setFileList([{ uid: "not_new", name: auxForm.img }]);
        setFilePreview(auxForm.img);
        auxForm.img = [{ uid: "not_new", name: auxForm.img }];
      }
      form.setFieldsValue(auxForm);
    }
  }, [data]);

  async function handleSubmit(values) {
    setIsButtonLoading(true);

    let formData = new FormData();

    for (const file of fileList) {
      let compressedFile = await upload.compress(file);
      formData.append("file", compressedFile);
    }

    values.name = "sponsor";
    formData.append("data", JSON.stringify(values));

    axios
      .post(config.server_ip + endpoints.settings.update, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res);
        setIsButtonLoading(false);
        notification.success({
          message: "Sucesso!",
          description: "Patrocinador foi editado com sucesso!",
        });
      })
      .catch((error) => {
        console.log(error);
        setIsButtonLoading(false);
        notification.error({
          message: "Oops!",
          description: "Algo de errado aconteceu! Tente novamente mais tarde.",
        });
      });
  }

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: "Faltam alguns campos para preencher!",
    });
  }

  async function handlePreview(file, type) {
    let filePreview = await getBase64(file);
    setFilePreview(filePreview);
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  return (
    <div className="dashboard_page">
      <Row className="dashboard_content_row">
        <Col span={24}>
          <Form form={form} id="settings-sponsor-form" layout="vertical" onFinish={handleSubmit} onFinishFailed={handleWarningMissingFields}>
            <Form.Item hidden name="name">
              <Input />
            </Form.Item>
            <Form.Item name="img" label="Imagem" valuePropName="fileList" getValueFromEvent={normFile}>
              <Dragger {...uploadProps} className={`dragger ${filePreview ? "hasPreview" : ""}`}>
                <p className="ant-upload-drag-icon">
                  <FileImageOutlined />
                </p>
                <p className="ant-upload-text">Clique ou arraste a imagem para esta zona para upload</p>
                <div
                  className={`preview_file ${filePreview ? "hasPreview" : ""}`}
                  style={{ backgroundImage: `url(${filePreview && filePreview.includes("base64") ? filePreview : `${config.server_ip}/images/${filePreview}`})` }}
                ></div>
              </Dragger>
            </Form.Item>
            <Row>
              <Col span={24}>
                <Button type="primary" size="large" onClick={form.submit} loading={isButtonLoading}>
                  Guardar
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default SettingsSponsor;
