import { Row, Col, Button, Spin, Divider, Pagination, Empty, Form, Input } from "antd";
import { EditOutlined, DeleteOutlined, ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";

import spnLogo from "../../assets/images/SPN-grey-logo.svg";
import apedtLogo from "../../assets/images/APEDT-grey-logo.svg";
import config from "../../utils/config";
import Create from "../../components/Faculty/Create";
import Update from "../../components/Faculty/Update";
import Delete from "../../components/Faculty/Delete";

import optionsType from "../../utils/optionsType";
import endpoints from "../../utils/endpoints";

import "./Faculty.css";

function Faculty() {
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedData, setSelectedData] = useState({});

  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(5);

  const [form] = Form.useForm();

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.faculty.read)
      .then((res) => {
        setData(res.data);
        handlePrepareData(res.data);

        if (form.getFieldValue("search")) {
          handleSearch(form.getFieldsValue());
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  function handlePrepareData(items) {
    setIsLoading(true);
    let auxArray = [];
    for (let i = 0; i < items.length; i++) {
      let arrayData = items[i];

      let aux = {
        key: i,
        img: <div className="img-preview" style={{ backgroundImage: `url('${config.server_ip}/images/${arrayData.img ? arrayData.img : "User-default.svg"}')` }}></div>,
        name: arrayData.name,
        specialty: arrayData.specialty,
        institution: arrayData.institution,
        type: <img className="logo-program-table" src={arrayData.type === "SPN" ? spnLogo : apedtLogo} />,
        actions: (
          <div className="actions-buttons-div">
            <EditOutlined className="edit-action" onClick={() => handleOpenUpdate(arrayData)} />
            <DeleteOutlined className="delete-action" onClick={() => handleOpenDelete(arrayData)} />
          </div>
        ),
      };

      auxArray.push(aux);
    }

    setTableData(auxArray);
    setIsLoading(false);
  }

  function handleChangePage(e, p) {
    setItemsPerPage(p);
    setCurrentPage(e);
    if (e <= 1) {
      setMinValue(0);
      setMaxValue(p);
    } else {
      let newMinValue = p * (e - 1);
      let newMaxValue = newMinValue + p;
      setMinValue(newMinValue);
      setMaxValue(newMaxValue);
    }
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetData();
  }

  function handleOpenUpdate(data) {
    setSelectedData(data);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    handleGetData();
  }

  function handleOpenDelete(data) {
    setSelectedData(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setIsOpenDelete(false);
    setSelectedData({});
    handleGetData();
  }

  function handleSearch(values) {
    const newData = data.filter((item) => item.name.toLowerCase().includes(values.search.toLowerCase()));
    handlePrepareData(newData);
  }

  return (
    <div className="dashboard_page">
      <Create open={isOpenCreate} close={handleCloseCreate} optionsType={optionsType} />
      <Update data={selectedData} open={isOpenUpdate} close={handleCloseUpdate} optionsType={optionsType} />
      <Delete data={selectedData} open={isOpenDelete} close={handleCloseDelete} />
      <Row className="title_row mb-20 dashboard_title_row">
        <Col span={16} className="d-flex jc-start ai-center">
          <h3 className="mt-0 mb-0">Faculty</h3>
        </Col>
        <Col span={8} className="d-flex jc-end ai-center">
          <Button size="large" onClick={handleGetData} className="mr-10">
            <ReloadOutlined />
          </Button>
          <Form form={form} onValuesChange={handleSearch} className="mr-10">
            <Form.Item name="search" noStyle>
              <Input prefix={<SearchOutlined />} className="mr-20" size="large" placeholder="Procurar..." allowClear />
            </Form.Item>
          </Form>
          <Button size="large" onClick={handleOpenCreate}>
            Adicionar
          </Button>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={isLoading}>
        <Row className="dashboard_content_row">
          <Col span={24}>
            {tableData.length > 0 ? (
              <div className="d-flex flex-column jc-sb h-100">
                <div>
                  {tableData.slice(minValue, maxValue).map((item) => {
                    return (
                      <Row className="table_item" key={item.id}>
                        <Col span={2}>
                          <div className="d-flex jc-center ai-center mr-20">{item.img}</div>
                        </Col>
                        <Col span={6}>
                          <div className="d-flex flex-column">
                            <span className="f-12">Nome</span>
                            <span className="f-18 semi-bold">{item.name}</span>
                          </div>
                        </Col>
                        <Col span={4}>
                          <div className="d-flex flex-column">
                            <span className="f-12">Especialidade</span>
                            <span className="f-18 semi-bold">{item.specialty ? item.specialty : "TBA"}</span>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div className="d-flex flex-column">
                            <span className="f-12">Instituição</span>
                            <span className="f-18 semi-bold">{item.institution ? item.institution : "TBA"}</span>
                          </div>
                        </Col>
                        <Col span={4} className="d-flex jc-center ai-center">
                          {item.type}
                        </Col>
                        <Col span={2} className="d-flex jc-end ai-center">
                          {item.actions}
                        </Col>
                      </Row>
                    );
                  })}
                </div>
                <div className="d-flex jc-center ai-center">
                  <Pagination onChange={handleChangePage} pageSize={itemsPerPage} defaultCurrent={1} current={currentPage} total={tableData.length} />
                </div>
              </div>
            ) : (
              <Row className="d-flex jc-center ai-center empty_data">
                <Col span={24} className="d-flex flex-column jc-center ai-center">
                  <Empty description="Sem dados a apresentar" />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default Faculty;
