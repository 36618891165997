import { Row, Col, Button, notification, Divider, Pagination, Spin, Empty } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  SendOutlined,
  ReloadOutlined,
  AndroidOutlined,
  AppleOutlined,
  UserOutlined,
  MessageOutlined,
  QuestionCircleOutlined,
  UsergroupAddOutlined,
  TeamOutlined,
  NotificationOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";

import "./Dashboard.css";

import config from "../../utils/config";
import endpoints from "../../utils/endpoints";
import { Link } from "react-router-dom";

function Dashboard() {
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState({});

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    axios
      .get(endpoints.info.read)
      .then((res) => {
        console.log(res);
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  return (
    <Row gutter={[24, 24]}>
      <Col span={8}>
        <div className="bg-white" style={{ borderRadius: 8 }}>
          <Row className="p-20">
            <Col span={12} className="d-flex jc-start ai-center">
              <p className="mt-0 mb-0 blue f-20 bold">Dispositivos</p>
            </Col>
          </Row>
          <Divider className="mb-0 mt-0" />
          <Spin spinning={isLoading}>
            <Row className="p-20">
              <Col span={12} className="d-flex flex-column jc-center ai-center">
                <AppleOutlined className="f-30 blue" />
                <p className="f-30 bold text-center mb-0 mt-10">{data.devices ? data.devices.filter((item) => item.platform === "ios")?.length : 0}</p>
                <p>iOS</p>
              </Col>
              <Col span={12} className="d-flex flex-column jc-center ai-center">
                <AndroidOutlined className="f-30 blue" />
                <p className="f-30 bold text-center mb-0 mt-10">
                  {data.devices?.filter((item) => item.platform === "android") ? data.devices?.filter((item) => item.platform === "android").length : 0}
                </p>
                <p>Android</p>
              </Col>
            </Row>
          </Spin>
        </div>
      </Col>
      <Col span={8}>
        <div className="bg-white h-100" style={{ borderRadius: 8 }}>
          <Row className="p-20">
            <Col span={12} className="d-flex jc-start ai-center">
              <p className="mt-0 mb-0 blue f-20 bold">Utilizadores</p>
            </Col>
          </Row>
          <Divider className="mb-0 mt-0" />
          <Spin spinning={isLoading}>
            <Row className="p-20">
              <Col span={24} className="d-flex flex-column jc-center ai-center">
                <UserOutlined className="f-30 blue" />
                <p className="f-30 bold text-center mb-0 mt-10">{data.users ? data.users.length : 0}</p>
                <p>Nº total de utilizadores</p>
              </Col>
            </Row>
          </Spin>
        </div>
      </Col>
      <Col span={8}>
        <div className="bg-white h-100" style={{ borderRadius: 8 }}>
          <Row className="p-20">
            <Col span={12} className="d-flex jc-start ai-center">
              <p className="mt-0 mb-0 blue f-20 bold">Mensagens</p>
            </Col>
          </Row>
          <Divider className="mb-0 mt-0" />
          <Spin spinning={isLoading}>
            <Row className="p-20">
              <Col span={24} className="d-flex flex-column jc-center ai-center">
                <MessageOutlined className="f-30 blue" />
                <p className="f-30 bold text-center mb-0 mt-10">{data.messages ? data.messages.length : 0}</p>
                <p>Nº total de mensagens</p>
              </Col>
            </Row>
          </Spin>
        </div>
      </Col>

      <Col span={6}>
        <div className="bg-white h-100" style={{ borderRadius: 8 }}>
          <Row className="p-20">
            <Col span={12} className="d-flex jc-start ai-center">
              <p className="mt-0 mb-0 blue f-20 bold">Faculty</p>
            </Col>
          </Row>
          <Divider className="mb-0 mt-0" />
          <Spin spinning={isLoading}>
            <Row className="p-20">
              <Col span={24} className="d-flex flex-column jc-center ai-center">
                <TeamOutlined className="f-30 blue" />
                <p className="f-30 bold text-center mb-0 mt-10">{data.faculty ? data.faculty.length : 0}</p>
                <p>Nº total de faculty</p>
              </Col>
            </Row>
          </Spin>
        </div>
      </Col>

      <Col span={6}>
        <div className="bg-white h-100" style={{ borderRadius: 8 }}>
          <Row className="p-20">
            <Col span={12} className="d-flex jc-start ai-center">
              <p className="mt-0 mb-0 blue f-20 bold">Notificações</p>
            </Col>
          </Row>
          <Divider className="mb-0 mt-0" />
          <Spin spinning={isLoading}>
            <Row className="p-20">
              <Col span={24} className="d-flex flex-column jc-center ai-center">
                <NotificationOutlined className="f-30 blue" />
                <p className="f-30 bold text-center mb-0 mt-10">{data.notifications ? data.notifications.length : 0}</p>
                <p>Nº total de notificações</p>
              </Col>
            </Row>
          </Spin>
        </div>
      </Col>

      <Col span={6}>
        <div className="bg-white h-100" style={{ borderRadius: 8 }}>
          <Row className="p-20">
            <Col span={12} className="d-flex jc-start ai-center">
              <p className="mt-0 mb-0 blue f-20 bold">Salas</p>
            </Col>
          </Row>
          <Divider className="mb-0 mt-0" />
          <Spin spinning={isLoading}>
            <Row className="p-20">
              <Col span={24} className="d-flex flex-column jc-center ai-center">
                <HomeOutlined className="f-30 blue" />
                <p className="f-30 bold text-center mb-0 mt-10">{data.room ? data.room.length : 0}</p>
                <p>Nº total de salas</p>
              </Col>
            </Row>
          </Spin>
        </div>
      </Col>

      <Col span={6}>
        <div className="bg-white h-100" style={{ borderRadius: 8 }}>
          <Row className="p-20">
            <Col span={12} className="d-flex jc-start ai-center">
              <p className="mt-0 mb-0 blue f-20 bold">FAQs</p>
            </Col>
          </Row>
          <Divider className="mb-0 mt-0" />
          <Spin spinning={isLoading}>
            <Row className="p-20">
              <Col span={24} className="d-flex flex-column jc-center ai-center">
                <QuestionCircleOutlined className="f-30 blue" />
                <p className="f-30 bold text-center mb-0 mt-10">{data.faqs ? data.faqs.length : 0}</p>
                <p>Nº total de faqs</p>
              </Col>
            </Row>
          </Spin>
        </div>
      </Col>
    </Row>
  );
}

export default Dashboard;
