const endpoints = {
  auth: {
    login: "/auth/login",
    verifyToken: "/auth/verifyToken",
  },
  user: {
    read: "/user/read",
    update: "/user/update",
  },
  program: {
    read: "/program/read",
    readByType: "/program/readByType",
    readByFaculty: "/program/readByFaculty",
    create: "/program/create",
    update: "/program/update",
    delete: "/program/delete",
  },
  faculty: {
    read: "/faculty/read",
    readByType: "/faculty/readByType",
    create: "/faculty/create",
    update: "/faculty/update",
    delete: "/faculty/delete",
  },
  commission: {
    read: "/commission/read",
    readByType: "/commission/readByType",
    readType: "/commission/readType",
    readPosition: "/commission/readPosition",
    create: "/commission/create",
    createType: "/commission/createType",
    createPosition: "/commission/createPosition",
    update: "/commission/update",
    updateType: "/commission/updateType",
    updatePosition: "/commission/updatePosition",
    delete: "/commission/delete",
    deleteType: "/commission/deleteType",
  },
  sponsor: {
    read: "/sponsor/read",
    readType: "/sponsor/readType",
    create: "/sponsor/create",
    createType: "/sponsor/createType",
    update: "/sponsor/update",
    updateType: "/sponsor/updateType",
    delete: "/sponsor/delete",
    deleteType: "/sponsor/deleteType",
    readScientific: "/sponsor/readScientific",
    createScientific: "/sponsor/createScientific",
    updateScientific: "/sponsor/updateScientific",
    deleteScientific: "/sponsor/deleteScientific",
  },
  welcomeLetter: {
    read: "/welcomeLetter/read",
    create: "/welcomeLetter/create",
    update: "/welcomeLetter/update",
    delete: "/welcomeLetter/delete",
    upload: "/welcomeLetter/upload",
  },
  notification: {
    read: "/notification/read",
    create: "/notification/create",
    update: "/notification/update",
    delete: "/notification/delete",
    send: "/notification/send",
  },
  settings: {
    read: "/settings/read",
    readByName: "/settings/readByName",
    update: "/settings/update",
    upload: "/settings/upload",
  },
  faq: {
    read: "/faq/read",
    create: "/faq/create",
    delete: "/faq/delete",
  },
  room: {
    read: "/room/read",
    create: "/room/create",
    update: "/room/update",
    delete: "/room/delete",
  },
  info: {
    read: "/info/read",
  },
  credits: {
    read: "/credits/read",
    update: "/credits/update",
    upload: "/credits/upload",
  },
};

export default endpoints;
