import imageCompression from "browser-image-compression";

const upload = {
  compress: (file) => {
    return new Promise(async (resolve, reject) => {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(file, options);
        console.log(compressedFile);
        const newFile = new File([compressedFile], file.name);
        resolve(newFile);
      } catch (error) {
        reject(error);
      }
    });
  },
};

export default upload;
