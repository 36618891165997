import { Row, Col, Button, Input, Form, notification, DatePicker } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../utils/config.js";

import "./Settings.css";
import "react-quill/dist/quill.snow.css";
import endpoints from "../../utils/endpoints.js";
import dayjs from "dayjs";

function SettingsCountdown({ data }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (data.length > 0) {
      let countdownData = data.filter((item) => item.name === "countdown")[0];
      let aux = JSON.parse(countdownData.json);
      aux.time = dayjs(aux.time);
      form.setFieldsValue(aux);
    }
  }, [data]);

  function handleSubmit(values) {
    setIsButtonLoading(true);
    values.time = dayjs(values.time).format("YYYY-MM-DD HH:mm:ss");
    axios
      .post(endpoints.settings.update, {
        data: { values: values, name: "countdown" },
      })
      .then((resData) => {
        setIsButtonLoading(false);
        notification.success({
          message: "Sucesso!",
          description: "Definições foram editadas com sucesso!",
        });
      })
      .catch((error) => {
        console.log(error);
        setIsButtonLoading(false);
        notification.error({
          message: "Oops!",
          description: "Algo de errado aconteceu! Tente novamente mais tarde.",
        });
      });
  }

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: "Faltam alguns campos para preencher!",
    });
  }

  return (
    <div className="dashboard_page">
      <Row className="dashboard_content_row">
        <Col span={24}>
          <Form form={form} id="add-commission_type-form" layout="vertical" onFinish={handleSubmit} onFinishFailed={handleWarningMissingFields}>
            <Row>
              <Col span={24}>
                <Form.Item hidden name="id">
                  <Input />
                </Form.Item>
                <Form.Item label="Countdown" name={"time"}>
                  <DatePicker className="w-100" showTime size="large" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Button type="primary" size="large" onClick={form.submit} loading={isButtonLoading}>
                  Guardar
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default SettingsCountdown;
