import { Breadcrumb, Layout, Menu, theme, Row, Col, Button } from "antd";
import { useState, useEffect } from "react";
import { Outlet, RouterProvider, useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  DashboardOutlined,
  FileOutlined,
  FileTextOutlined,
  LogoutOutlined,
  NotificationOutlined,
  MailOutlined,
  SettingOutlined,
  TeamOutlined,
  UserOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import axios from "axios";
import config from "../../utils/config";
import Loading from "../Loading/Loading";
import "./Main.css";
import endpoints from "../../utils/endpoints";

import logo from "../../assets/images/Logo-Back.svg";

const { Content, Sider, Header } = Layout;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [
  getItem("DADOS", "g1", null, [getItem("DASHBOARD", "dashboard", <DashboardOutlined />), ,], "group"),
  getItem(
    "EVENTO",
    "g2",
    null,
    [
      getItem("PROGRAMA", "program", <FileOutlined />),
      getItem("SALAS", "room", <FileOutlined />),
      getItem("FACULTY", "faculty", <TeamOutlined />),
      getItem("COMISSÃO", "sub2", <TeamOutlined />, [
        getItem("MEMBROS", "commission", <TeamOutlined />),
        getItem("TIPOS", "commission-type", <TeamOutlined />),
        getItem("POSIÇÃO", "commission-position", <TeamOutlined />),
      ]),
      getItem("FAQS", "faqs", <QuestionCircleOutlined />),
    ],
    "group"
  ),
  getItem("EXPOSIÇÃO TÉCNICA", "g3", null, [getItem("PATROCINADORES", "sponsors", <FileOutlined />), getItem("TIPOS", "sponsors-type", <FileOutlined />)], "group"),
  getItem(
    "CONFIGURAÇÕES",
    "g4",
    null,
    [
      getItem("CRÉDITOS", "credits", <MailOutlined />),
      getItem("CARTA BOAS VINDAS", "welcome-letter", <MailOutlined />),
      getItem("NOTIFICAÇÕES", "notifications", <NotificationOutlined />),
      getItem("UTILIZADORES", "users", <UserOutlined />),
      getItem("DEFINIÇÕES", "settings", <SettingOutlined />),
    ],
    "group"
  ),
];

const MainDashboard = ({ handleLogout }) => {
  const location = useLocation();
  let pathname = location.pathname.split("/");

  const [user, setuser] = useState({});
  const [current, setCurrent] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [collapsed, setCollapsed] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let auxPathname = location.pathname.split("/");
    setCurrent(auxPathname[auxPathname.length - 1] === "" ? "/" : `${auxPathname[auxPathname.length - 1]}`);
  }, [location]);

  useEffect(() => {
    let tokenData = localStorage.getItem("token");
    if (tokenData) {
      axios
        .post(endpoints.auth.verifyToken, {
          data: tokenData,
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.token_valid) {
            setuser(res.data.token_decoded);
            setTimeout(() => {
              setIsLoading(false);
            }, 2000);
          } else {
            navigate("/login");
          }
        })
        .catch((err) => {
          console.log(err);
          navigate("/login");
        });
    } else {
      navigate("/login");
    }
  }, []);

  function handleClickMenu(e) {
    if (e.key === "logout") {
      handleLogout();
    } else {
      navigate(e.key);
    }
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Layout className="admin_layout">
          <Sider width={250}>
            <div className="logo p-20 minh-85 d-flex jc-center ai-center" onClick={() => navigate("/")}>
              <img src={logo} className="w-100" />
            </div>
            <Menu theme="dark" defaultSelectedKeys={[current]} mode="inline" items={items} onClick={handleClickMenu} />
          </Sider>
          <Layout className="site-layout">
            <Header style={{ padding: 0, background: "#05253a" }}>
              <Row className="h-100">
                <Col span={12}></Col>
                <Col span={12} className="d-flex jc-end ai-center">
                  <div className="user_header">
                    <UserOutlined />
                    {user.name}
                  </div>
                  <div className="logout_header">
                    <Button type="text" icon={<LogoutOutlined />} onClick={handleLogout}>
                      {" "}
                      Terminar Sessão
                    </Button>
                  </div>
                </Col>
              </Row>
            </Header>
            <Content className="admin_layout_content">
              <Outlet context={user} />
            </Content>
          </Layout>
        </Layout>
      )}
    </>
  );
};
export default MainDashboard;
