import { Row, Col, Button, Input, DatePicker, TimePicker, Select, Radio, Form, notification, Drawer, Switch, ColorPicker } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import ReactQuill from "react-quill";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";

import CreateFaculty from "../Faculty/Create.js";

import endpoints from "../../utils/endpoints.js";

import "react-quill/dist/quill.snow.css";

const { Option } = Select;
const { RangePicker } = TimePicker;

const defaultFormList = { description: [{ themes: [], speakers: [] }], mods: [], title_color: "#384F2E", add_to_calendar: true, has_description: true };

function Update({ data, open, close, optionsType, faculty, rooms }) {
  const [facultyData, setFacultyData] = useState([]);

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isOpenCreateFaculty, setIsOpenCreateFaculty] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (Object.keys(data).length > 0 && open) {
      data.moderators = data.moderators === null ? [] : data.moderators;
      data.speakers = data.speakers === null ? [] : data.speakers;
      data.description = data.description === null ? [{}] : typeof data.description === "object" ? data.description : JSON.parse(data.description);

      form.setFieldsValue(data);
      setFacultyData(faculty);
    }
  }, [data]);

  function handleSubmit(values) {
    setIsButtonLoading(true);

    let speakersAux = [];
    if (values.has_description) {
      let auxDescription = values.description;
      for (let i = 0; i < auxDescription.length; i++) {
        let auxSpeakersArray = auxDescription[i].speakers;
        if (auxSpeakersArray) {
          for (let z = 0; z < auxSpeakersArray.length; z++) {
            if (!speakersAux.includes(auxSpeakersArray[z])) {
              speakersAux.push(auxSpeakersArray[z]);
            }
          }
        } else {
          auxDescription[i].speakers = [];
        }
      }
    }

    let obj = {
      id: data.id,
      title: values.title,
      title_color: values.title_color,
      has_description: values.has_description,
      description: JSON.stringify(values.description),
      id_room: values.id_room,
      date: dayjs(values.date).format("YYYY-MM-DD"),
      hour_start: dayjs(values.duration[0]).format("HH:mm:ss"),
      hour_end: dayjs(values.duration[1]).format("HH:mm:ss"),
      mods: values.mods,
      speakers: speakersAux,
      type: values.type,
      add_to_calendar: values.add_to_calendar ? 1 : 0,
      aha_slides: values.aha_slides ? 1 : 0,
      aha_slides_active: values.aha_slides_active ? 1 : 0,
      button_name: values.button_name,
      button_link: values.button_link,
    };

    axios
      .post(endpoints.program.update, {
        data: obj,
      })
      .then((res) => {
        setTimeout(() => {
          setIsButtonLoading(false);
          form.resetFields();
          close();
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        setIsButtonLoading(false);
        notification.error({
          message: "Oops!",
          description: "Algo de errado aconteceu! Tente novamente mais tarde.",
        });
      });
  }

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: "Faltam alguns campos para preencher!",
    });
  }

  function handleCloseDrawer() {
    close();
    setIsButtonLoading(false);
    form.resetFields();
  }

  function handleOpenCreateFaculty() {
    setIsOpenCreateFaculty(true);
  }

  function handleCloseCreateFaculty(data) {
    if (data) {
      let facultyAux = JSON.parse(JSON.stringify(faculty));

      facultyAux.push({ id: data.id, name: data.name });
      facultyAux.sort((a, b) => {
        let fa = a.name.toLowerCase();
        let fb = b.name.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      setFacultyData(facultyAux);
    }
    setIsOpenCreateFaculty(false);
  }

  return (
    <Drawer
      width={800}
      className="drawer-antd-program"
      title={`Editar programa`}
      open={open}
      onClose={handleCloseDrawer}
      maskClosable={false}
      extra={[
        <Button className="mr-10" size="large" type="primary" onClick={form.submit} loading={isButtonLoading} disabled={isButtonLoading}>
          Guardar
        </Button>,
        <Button size="large" onClick={handleCloseDrawer}>
          Cancelar
        </Button>,
      ]}
    >
      <CreateFaculty open={isOpenCreateFaculty} close={handleCloseCreateFaculty} optionsType={optionsType} />
      <Form form={form} initialValues={defaultFormList} id="add-program-form" layout="vertical" onFinish={handleSubmit} onFinishFailed={handleWarningMissingFields}>
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Form.Item name="type" rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
              <Radio.Group options={optionsType} optionType="button" buttonStyle="solid" className="radio-type" />
            </Form.Item>
            <Row gutter={[24]}>
              <Col span={20}>
                <Form.Item name="title" label="Título" rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
                  <ReactQuill placeholder="Título" className="title_react_quill" theme="snow" />
                </Form.Item>
              </Col>
              <Col span={4} className="d-flex jc-center ai-center">
                <Form.Item
                  getValueFromEvent={(color) => {
                    return "#" + color.toHex();
                  }}
                  label="Cor do título"
                  name="title_color"
                  key={`orientation_color_picker`}
                >
                  <ColorPicker getPopupContainer={(trigger) => trigger.parentNode} size="large" showText />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24]}>
              <Col span={22}>
                <Form.Item name="mods" label="Moderadores">
                  <Select
                    showSearch
                    allowClear
                    mode="multiple"
                    size="large"
                    style={{ width: "100%" }}
                    placeholder="Selecione..."
                    filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                  >
                    {facultyData.map((value, index) => {
                      return (
                        <Option key={value.id} value={value.id}>
                          {value.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2} className="d-flex jc-center ai-center">
                <PlusCircleOutlined className="icon_add" onClick={handleOpenCreateFaculty} />
              </Col>
            </Row>
            <Form.Item name="has_description" label="Contém temas e intervenientes?" valuePropName="checked">
              <Switch defaultChecked size="large" />
            </Form.Item>

            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.has_description !== currentValues.has_description}>
              {({ getFieldValue }) => {
                let has_description = getFieldValue("has_description");
                return (
                  <Form.List name="description" label="Descrição">
                    {(fields, { add, remove }) => (
                      <Row gutter={[24]}>
                        <Col span={24}>
                          {fields.map(({ key, name, ...restField }, index) => (
                            <Row style={{ display: has_description ? "block" : "none" }}>
                              <Col span={24}>
                                <Row className={`row_description ${has_description ? "" : "disabled"}`}>
                                  {fields.length > 1 && <DeleteOutlined className="delete_description delete-action" onClick={() => remove(name)} style={{ fontSize: "20px" }} />}
                                  <Col span={24}>
                                    <Form.Item {...restField} label="Temas" name={[name, "themes"]}>
                                      <ReactQuill readOnly={!has_description} placeholder="Tema..." className="description_react_quill" theme="snow" />
                                    </Form.Item>
                                  </Col>
                                  <Col span={24}>
                                    <Row>
                                      <Col span={22}>
                                        <Form.Item className="mb-0" label="Palestrantes" name={[name, "speakers"]}>
                                          <Select
                                            disabled={!has_description}
                                            showSearch
                                            allowClear
                                            key={"speakers"}
                                            mode="multiple"
                                            size="large"
                                            style={{ width: "100%" }}
                                            placeholder="Selecione..."
                                            filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                                          >
                                            {faculty.map((value, index) => {
                                              return (
                                                <Option key={index} value={value.id}>
                                                  {value.name}
                                                </Option>
                                              );
                                            })}
                                          </Select>
                                        </Form.Item>
                                      </Col>
                                      <Col span={2} className="d-flex jc-center ai-center">
                                        <PlusCircleOutlined className="icon_add mt-24" onClick={handleOpenCreateFaculty} />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          ))}
                        </Col>

                        <Col span={24} className="mb-20 d-flex jc-center ai-center mt-20">
                          <Button disabled={!has_description} size="large" onClick={() => add()}>
                            <PlusCircleOutlined /> Adicionar
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </Form.List>
                );
              }}
            </Form.Item>
            <Form.Item name="id_room" label="Sala">
              <Select
                showSearch
                allowClear
                key={"rooms"}
                size="large"
                style={{ width: "100%" }}
                placeholder="Selecione..."
                filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                options={rooms.map((value, index) => ({ value: value.id, label: value.name }))}
              />
            </Form.Item>
            <Row gutter={[24]}>
              <Col span={12}>
                <Form.Item name="date" label="Data" rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
                  <DatePicker key={"data"} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="duration" label="Duração" rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
                  <RangePicker format={"HH:mm"} key={"hora"} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="add_to_calendar" label="Adicionar ao calendário?" valuePropName="checked" tooltip="Permitir que o utilizador adicione ao calendário">
              <Switch />
            </Form.Item>
            <Row gutter={[24]}>
              <Col span={6}>
                <Form.Item name="aha_slides" label="Questionário" valuePropName="checked" tooltip="Adicionar link de Questionário a este elemento do programa">
                  <Switch size="large" />
                </Form.Item>
              </Col>
              <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.aha_slides !== currentValues.aha_slides}>
                {({ getFieldValue }) => {
                  if (getFieldValue("aha_slides")) {
                    return (
                      <Col span={6}>
                        <Form.Item name="aha_slides_active" label="Questionário Ativo" valuePropName="checked" tooltip="Ativar botão de Questionário para o utilizador">
                          <Switch disabled={!getFieldValue("aha_slides")} size="large" />
                        </Form.Item>
                      </Col>
                    );
                  }
                }}
              </Form.Item>
            </Row>
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.aha_slides !== currentValues.aha_slides}>
              {({ getFieldValue }) => {
                if (getFieldValue("aha_slides")) {
                  return (
                    <Row gutter={[24]}>
                      <Col span={12}>
                        <Form.Item name="button_name" label="Botão Questionário" tooltip="Nome do botão para o Questionário">
                          <Input disabled={!getFieldValue("aha_slides")} size="large" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item name="button_link" label="Link Questionário" tooltip="Link do botão para o Questionário">
                          <Input disabled={!getFieldValue("aha_slides")} size="large" />
                        </Form.Item>
                      </Col>
                    </Row>
                  );
                }
              }}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}

export default Update;
