import spnLogo from "../assets/images/SPN-grey-logo.svg";
import apedtLogo from "../assets/images/APEDT-grey-logo.svg";

const optionsType = [
  {
    label: <img src={spnLogo} />,
    value: "SPN",
  },
  {
    label: <img src={apedtLogo} />,
    value: "APEDT",
  },
];
export default optionsType;
