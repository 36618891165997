import { Layout, Row, Col, Spin } from "antd";
import { HeartFilled } from "@ant-design/icons";
import logo from "../../assets//images/Encontro-Renal-About.png";
import "./Loading.css";

export default function Loading() {
  return (
    <div className="loading_div">
      <div className="logo">
        <img src={logo} />
      </div>
      <Spin spinning={true} />
    </div>
  );
}
