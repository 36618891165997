import { Row, Col, Button, Input, Form, notification } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../utils/config.js";

import "./Settings.css";
import "react-quill/dist/quill.snow.css";
import endpoints from "../../utils/endpoints.js";

function SettingsCommunications({ data }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (data.length > 0) {
      let auxData = data.filter((item) => item.name === "communications")[0];
      form.setFieldsValue(JSON.parse(auxData.json));
    }
  }, [data]);

  function handleSubmit(values) {
    setIsButtonLoading(true);

    axios
      .post(endpoints.settings.update, {
        data: { values: values, name: "communications" },
      })
      .then((resData) => {
        setIsButtonLoading(false);
        notification.success({
          message: "Sucesso!",
          description: "Definições foram editadas com sucesso!",
        });
      })
      .catch((error) => {
        console.log(error);
        setIsButtonLoading(false);
        notification.error({
          message: "Oops!",
          description: "Algo de errado aconteceu! Tente novamente mais tarde.",
        });
      });
  }

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: "Faltam alguns campos para preencher!",
    });
  }

  return (
    <div className="dashboard_page">
      <Row className="dashboard_content_row">
        <Col span={24}>
          <Form form={form} id="add-commission_type-form" layout="vertical" onFinish={handleSubmit} onFinishFailed={handleWarningMissingFields}>
            <Form.Item hidden name="id">
              <Input />
            </Form.Item>
            <Form.Item label="SPN" name={"spn"}>
              <Input size="large" placeholder="Link Comunicações SPN" />
            </Form.Item>
            <Form.Item label="APEDT" name={"apedt"}>
              <Input size="large" placeholder="Link Comunicações APEDT" />
            </Form.Item>
            <Row>
              <Col span={24}>
                <Button type="primary" size="large" onClick={form.submit} loading={isButtonLoading}>
                  Guardar
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default SettingsCommunications;
