import { Row, Col, Divider, Button, Spin, notification, Upload, Form, Segmented, Input } from "antd";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import { DeleteOutlined, FileImageOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons";

import spnLogo from "../../assets/images/SPN-grey-logo.svg";
import apedtLogo from "../../assets/images/APEDT-grey-logo.svg";

import config from "../../utils/config.js";
import endpoints from "../../utils/endpoints.js";

import "./Credits.css";

import "react-quill/dist/quill.snow.css";
import upload from "../../utils/upload.js";

const { Dragger } = Upload;

function Credits() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const [optionSelected, setOptionSelected] = useState("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [filePreview, setFilePreview] = useState("");

  const [form] = Form.useForm();

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ align: [] }],
          ["bold", "italic", "underline", "strike"], // toggled buttons
          ["blockquote", "code-block"],
          ["link"],
          [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
          [{ script: "sub" }, { script: "super" }], // superscript/subscript
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme

          ["clean"], // remove formatting button
        ],
      },
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
    }),
    []
  );

  const uploadProps = {
    name: "file",
    maxCount: 1,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFilePreview("");
    },
    beforeUpload: (file) => {
      handlePreview(file, "image");
      setFileList([file]);
      return false;
    },
    fileList: fileList,
    defaultFileList: [],
  };

  useEffect(() => {
    handleGetData();
  }, []);

  async function handlePreview(file, type) {
    let filePreview = await getBase64(file);
    setFilePreview(filePreview);
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.credits.read)
      .then((res) => {
        console.log(res.data);
        if (res.data.length > 0) {
          setData(res.data);

          if (res.data.length > 0 && res.data[0].img) {
            setFileList([{ uid: "not_new", name: res.data[0].img }]);
            setFilePreview(res.data[0].img);
            res.data[0].img = [{ uid: "not_new", name: res.data[0].img }];
          }

          form.setFieldsValue(res.data[0]);
          setOptionSelected(res.data[0].name);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  async function handleSubmit(values) {
    setIsLoading(true);
    setIsButtonLoading(true);

    let formData = new FormData();
    if (fileList.length > 0) {
      for (const file of fileList) {
        if (file.uid !== "not_new") {
          let compressedFile = await upload.compress(file);
          formData.append("file", compressedFile);
        } else {
          values.img = file.name;
        }
      }
    } else {
      values.img = null;
    }
    formData.append("data", JSON.stringify(values));

    axios
      .post(endpoints.credits.update, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        notification.success({
          message: "Sucesso!",
          description: "Créditos foram editados com sucesso!",
        });
        setIsLoading(false);
        setIsButtonLoading(false);
        handleGetData();
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setIsButtonLoading(false);
        notification.error({
          message: "Oops!",
          description: "Algo de errado aconteceu! Tente novamente mais tarde.",
        });
      });
  }

  function handleChangeSegment(value) {
    setIsLoading(true);
    let auxData = data.filter((item) => item.name === value)[0];

    if (auxData && auxData.img) {
      setFileList([{ uid: "not_new", name: typeof auxData.img === "string" ? auxData.img : auxData.img[0].name }]);
      setFilePreview(typeof auxData.img === "string" ? auxData.img : auxData.img[0].name);
      auxData.img = [{ uid: "not_new", name: typeof auxData.img === "string" ? auxData.img : auxData.img[0].name }];
    }

    form.setFieldsValue(auxData);
    setOptionSelected(value); // string
    setIsLoading(false);
  }

  return (
    <div className="dashboard_page">
      <Row className="title_row mb-20 dashboard_title_row">
        <Col span={20} className="d-flex jc-start ai-center">
          <h3 className="mt-0 mb-0">Créditos</h3>
        </Col>
        <Col span={4} className="d-flex jc-end ai-center">
          <Button size="large" onClick={handleGetData} className="mr-10">
            <ReloadOutlined />
          </Button>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={isLoading}>
        <Row className="dashboard_content_row">
          <Col span={24}>
            <Segmented
              value={optionSelected}
              options={[
                {
                  label: "ECMEC's",
                  value: "ECMEC's",
                },
                {
                  label: "ERA",
                  value: "ERA",
                },
              ]}
              onChange={handleChangeSegment}
            />

            <Row className="dashboard_content_row">
              <Col span={24}>
                <Form form={form} id="credits-form" layout="vertical" onFinish={handleSubmit}>
                  <Form.Item name="id" hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item name={"img"} label="Imagem">
                    <Dragger {...uploadProps} className={`dragger ${filePreview ? "hasPreview" : ""}`}>
                      <p className="ant-upload-drag-icon">
                        <FileImageOutlined
                          style={{
                            color: "rgb(103 103 103)",
                          }}
                        />
                      </p>
                      <p className="f-18 mb-0">
                        <b>Upload da imagem</b>
                      </p>
                      <p className="mt-0">Faça Drag and drop aqui</p>
                      <div
                        className={`preview_file sponsor ${filePreview ? "hasPreview" : ""}`}
                        style={{ backgroundImage: `url(${filePreview && filePreview.includes("base64") ? filePreview : `${config.server_ip}/images/${filePreview}`})` }}
                      ></div>
                    </Dragger>
                  </Form.Item>
                  <Form.Item label="Texto" name="text">
                    <ReactQuill key="quill-editor" className="welcome_letter" modules={modules} theme="snow" />
                  </Form.Item>

                  <Row className="mt-20 d-flex jc-center ai-center">
                    <Col span={24}>
                      <Button loading={isButtonLoading} size="large" type="primary" onClick={form.submit}>
                        Guardar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default Credits;
