import { Row, Col, Button, Divider, Avatar, Tooltip, Pagination, Spin, Form, Input, Empty } from "antd";
import { EditOutlined, DeleteOutlined, UserOutlined, SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { FiClock, FiCalendar, FiMapPin } from "react-icons/fi";

import Create from "../../components/Program/Create.js";
import Update from "../../components/Program/Update.js";
import Delete from "../../components/Program/Delete.js";

import endpoints from "../../utils/endpoints.js";
import optionsType from "../../utils/optionsType.js";
import config from "../../utils/config.js";

import spnLogo from "../../assets/images/SPN-grey-logo.svg";
import apedtLogo from "../../assets/images/APEDT-grey-logo.svg";

import "react-quill/dist/quill.snow.css";
import "./Program.css";

function Program() {
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [faculty, setFaculty] = useState([]);
  const [rooms, setRooms] = useState([]);

  const [selectedData, setSelectedData] = useState({});

  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(5);

  const [form] = Form.useForm();

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.program.read)
      .then((res) => {
        setData(res.data.program);
        setFaculty(res.data.faculty);
        setRooms(res.data.room);

        handlePrepareData(res.data.program, res.data.faculty);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  function handlePrepareData(data, facultyData) {
    setIsLoading(true);

    let auxArray = [];

    for (let i = 0; i < data.length; i++) {
      let mods = [];
      let speakers = [];
      let modsArray = JSON.parse(data[i].mods);
      let speakersArray = JSON.parse(data[i].speakers);

      if (modsArray !== null) {
        for (let z = 0; z < modsArray.length; z++) {
          let modOfProgram = facultyData.find((x) => x.id === modsArray[z]);
          mods.push(modOfProgram);
        }
      }

      if (speakersArray !== null) {
        for (let z = 0; z < speakersArray.length; z++) {
          let speakerOfProgram = facultyData.find((x) => x.id === speakersArray[z]);
          speakers.push(speakerOfProgram);
        }
      }

      let arrayData = data[i];

      let aux = {
        key: i,
        title: <span className="title-program" style={{ color: arrayData.title_color }} dangerouslySetInnerHTML={{ __html: arrayData.title }}></span>,
        room_name: arrayData.room_name,
        mods: mods,
        speakers: speakers,
        date: dayjs(arrayData.date).format("YYYY-MM-DD"),
        hourStart: `${arrayData.hour_start.split(":")[0]}:${arrayData.hour_start.split(":")[1]}`,
        hourEnd: `${arrayData.hour_end.split(":")[0]}:${arrayData.hour_end.split(":")[1]}`,
        type_data: arrayData.type,
        type: <img className="logo-program-table" src={arrayData.type === "SPN" ? spnLogo : apedtLogo} />,
        actions: (
          <div className="actions-buttons-div">
            <EditOutlined className="edit-action" onClick={() => handleOpenUpdate(data[i])} />
            <DeleteOutlined className="delete-action" onClick={() => handleOpenDelete(data[i])} />
          </div>
        ),
      };

      auxArray.push(aux);
    }

    setTableData(auxArray);
    setIsLoading(false);
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetData();
  }

  function handleOpenUpdate(data) {
    if (data) {
      let objAux = {
        ...data,
        mods: data.mods ? JSON.parse(data.mods) : null,
        speakers: data.speakers ? JSON.parse(data.speakers) : null,
        date: dayjs(data.date),
        duration: [dayjs(data.hour_start, "HH:mm:ss"), dayjs(data.hour_end, "HH:mm:ss")],
      };

      setSelectedData(objAux);
      setIsOpenUpdate(true);
    }
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    setSelectedData({});
    handleGetData();
  }

  function handleOpenDelete(data) {
    setSelectedData(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setIsOpenDelete(false);
    setSelectedData({});
    handleGetData();
  }

  function handleSearch(e) {
    let value = e.target.value;
    const newData = data.filter((item) => item.title.toLowerCase().includes(value.toLowerCase()));

    handlePrepareData(newData, faculty);
  }

  function handleChangePage(e, p) {
    setCurrentPage(e);
    setItemsPerPage(p);
    if (e <= 1) {
      setMinValue(0);
      setMaxValue(p);
    } else {
      let newMinValue = p * (e - 1);
      let newMaxValue = newMinValue + p;
      setMinValue(newMinValue);
      setMaxValue(newMaxValue);
    }
  }

  return (
    <div className="dashboard_page">
      <Create open={isOpenCreate} close={handleCloseCreate} optionsType={optionsType} faculty={faculty} rooms={rooms} />
      <Update data={selectedData} open={isOpenUpdate} close={handleCloseUpdate} optionsType={optionsType} faculty={faculty} rooms={rooms} />
      <Delete data={selectedData} open={isOpenDelete} close={handleCloseDelete} />
      <Row className="p-20">
        <Col span={16} className="d-flex jc-start ai-center">
          <h3 className="mt-0 mb-0">Programa</h3>
        </Col>
        <Col span={8} className="d-flex jc-end ai-center">
          <Button size="large" onClick={handleGetData} className="mr-10">
            <ReloadOutlined />
          </Button>
          <Form form={form} onValuesChange={handleSearch} className="mr-10">
            <Form.Item name="search" noStyle>
              <Input prefix={<SearchOutlined />} className="mr-20" size="large" placeholder="Procurar..." allowClear />
            </Form.Item>
          </Form>
          <Button type="primary" size="large" onClick={handleOpenCreate}>
            Adicionar
          </Button>
        </Col>
      </Row>
      <Divider className="mt-0" />
      <Spin spinning={isLoading}>
        <Row className="dashboard_content_row">
          <Col span={24}>
            {tableData.length > 0 ? (
              <div className="d-flex flex-column jc-sb h-100">
                <div>
                  {tableData.slice(minValue, maxValue).map((item) => {
                    return (
                      <Row className="program_item">
                        <Col span={24}>
                          <Row gutter={[24]}>
                            <Col span={10} className="d-flex jc-start ai-center">
                              <div className="d-flex flex-column">
                                <span className="f-12">Título</span>
                                <span className="f-18">{item.title}</span>
                              </div>
                            </Col>
                            <Col span={6} className="d-flex jc-start ai-center">
                              <div className="d-flex flex-column">
                                <span className="f-12">Moderadores</span>
                                <span className="mt-10">
                                  {item.mods.length > 0 ? (
                                    <Avatar.Group>
                                      {item.mods.map((itemMod) => (
                                        <Tooltip title={itemMod.name} placement="top">
                                          {itemMod.img ? <Avatar src={`${config.server_ip}/images/${itemMod.img}`} /> : <Avatar className="empty" icon={<UserOutlined />} />}
                                        </Tooltip>
                                      ))}
                                    </Avatar.Group>
                                  ) : (
                                    <Avatar.Group>
                                      <Avatar>TBA</Avatar>
                                    </Avatar.Group>
                                  )}
                                </span>
                              </div>
                            </Col>
                            <Col span={6} className="d-flex jc-start ai-center">
                              <div className="d-flex flex-column">
                                <span className="f-12">Palestrantes</span>
                                <span className="mt-10">
                                  {item.speakers.length > 0 ? (
                                    <Avatar.Group>
                                      {item.speakers.map((itemSpeaker) => (
                                        <Tooltip title={itemSpeaker.name} placement="top">
                                          {itemSpeaker.img ? (
                                            <Avatar src={`${config.server_ip}/images/${itemSpeaker.img}`} />
                                          ) : (
                                            <Avatar className="empty" icon={<UserOutlined />} />
                                          )}
                                        </Tooltip>
                                      ))}
                                    </Avatar.Group>
                                  ) : (
                                    <Avatar.Group>
                                      <Avatar>TBA</Avatar>
                                    </Avatar.Group>
                                  )}
                                </span>
                              </div>
                            </Col>
                            <Col span={2} className="d-flex jc-end ai-center">
                              {item.actions}
                            </Col>
                          </Row>
                          <Divider />
                          <Row className="info">
                            <Col span={6} className="d-flex jc-start ai-center">
                              <div className="d-flex jc-center ai-center">
                                <div>
                                  <FiCalendar className="mr-10" />
                                </div>
                                <div className="d-flex flex-column">
                                  <span className="f-12">Data</span>
                                  <span className="f-16 semi-bold">{item.date}</span>
                                </div>
                              </div>
                            </Col>
                            <Col span={6} className="d-flex jc-start ai-center">
                              <div className="d-flex jc-center ai-center">
                                <div>
                                  <FiClock className="mr-10" />
                                </div>
                                <div className="d-flex flex-column">
                                  <span className="f-12">Hora</span>
                                  <span className="f-16 semi-bold">
                                    {item.hourStart} - {item.hourEnd}
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col span={6}>{item.type}</Col>
                            <Col span={6}>
                              <div className="d-flex jc-center ai-center">
                                <div>
                                  <FiMapPin className="mr-10" />
                                </div>
                                <div className="d-flex flex-column">
                                  <span className="f-12">Sala</span>
                                  <span className="f-16 semi-bold">{item.room_name ? item.room_name : "TBA"}</span>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}
                </div>
                <div className="d-flex jc-center ai-center">
                  <Pagination onChange={handleChangePage} pageSize={itemsPerPage} defaultCurrent={1} current={currentPage} total={tableData.length} />
                </div>
              </div>
            ) : (
              <Row className="d-flex jc-center ai-center empty_data">
                <Col span={24} className="d-flex flex-column jc-center ai-center">
                  <Empty description="Sem dados a apresentar" />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default Program;
