import { Modal, Button, Input, Form, notification } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";

import endpoints from "../../utils/endpoints.js";

function UpdateType({ data, open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);

  function handleSubmit(values) {
    setIsButtonLoading(true);

    axios
      .post(endpoints.sponsor.updateType, {
        data: values,
      })
      .then((res) => {
        close();
        setIsButtonLoading(false);
        form.resetFields();
        notification.success({
          message: "Sucesso!",
          description: "Tipo de comissão foi editado com sucesso!",
        });
      })
      .catch((error) => {
        console.log(error);
        setIsButtonLoading(false);
        notification.error({
          message: "Oops!",
          description: "Algo de errado aconteceu! Tente novamente mais tarde.",
        });
      });
  }

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: "Faltam alguns campos para preencher!",
    });
  }

  function handleCloseModal() {
    close();
    form.resetFields();
  }

  return (
    <Modal
      className="modal-antd-commission_type"
      title={`Editar tipo de patrocinador`}
      open={open}
      onCancel={handleCloseModal}
      footer={[
        <Button size="large" type="primary" onClick={form.submit} loading={isButtonLoading} disabled={isButtonLoading}>
          Guardar
        </Button>,
        <Button size="large" onClick={handleCloseModal}>
          Cancelar
        </Button>,
      ]}
    >
      <Form form={form} id="add-commission_type-form" layout="vertical" onFinish={handleSubmit} onFinishFailed={handleWarningMissingFields}>
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Form.Item name="name" label="Nome" rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
          <Input size="large" placeholder="Nome..." />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default UpdateType;
